<template>
  <div>
    <v-row v-if="!veranstaltung.data.gastverein" class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="./" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-playlist-check</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-light">Gästeteam erfassen</h3>
        <h2 class="white--text font-weight-bold">
          {{ veranstaltung.data.gastmannschaft }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!veranstaltung.data.gastverein">
      <v-col cols="11" v-if="anwesende_spieler.length == 0">
        <p class="text-justify">
          Bisher wurden noch keine Mitglieder des Gästeteams erfasst. Sende dem
          Gast {{ veranstaltung.data.gastmannschaft }} einen Link zu, über den
          er die Daten des Gästeteams eingeben kann.
        </p>
      </v-col>
      <v-col cols="11" v-if="anwesende_spieler.length > 0">
        <v-divider class="my-4"></v-divider>
        <h3 class="font-weight-light text-center">
          Es {{ anwesende_spieler.length == 1 ? 'wurde' : 'wurden' }} bisher
          <span class="font-weight-bold">
            {{ anwesende_spieler.length }}
            {{ anwesende_spieler.length == 1 ? 'Person' : 'Personen' }}
          </span>
          des Gästeteams erfasst.
        </h3>
        <v-divider class="my-4"></v-divider>
      </v-col>
      <v-col cols="11" v-if="webShareApiSupported">
        <v-btn
          :color="template.colors.primary"
          large
          rounded
          :block="$vuetify.breakpoint.smAndDown"
          @click="shareViaWebShare()"
          v-if="webShareApiSupported"
        >
          Link an Gast senden
        </v-btn>
      </v-col>
      <v-col
        cols="9"
        class="text-center"
        v-if="!webShareApiSupported && veranstaltung.data.gastpin"
      >
        <v-text-field
          filled
          rounded
          prepend-inner-icon="mdi-link-variant"
          readonly
          label="Gastteam-Link"
          :value="`${template.var.app_url}/g/${user.data.alias}/${veranstaltung.id}_${veranstaltung.data.gastpin}`"
        ></v-text-field>
        <v-btn
          v-clipboard="
            `${template.var.app_url}/g/${user.data.alias}/${veranstaltung.id}_${veranstaltung.data.gastpin}`
          "
          :disabled="linkcopysuccess"
          large
          rounded
          :color="template.colors.primary"
          @click="linkcopysuccess = true"
        >
          <v-icon class="mr-2">
            {{ linkcopysuccess ? 'mdi-check-bold' : 'mdi-content-copy' }}
          </v-icon>
          {{ linkcopysuccess ? 'Link kopiert' : 'Link kopieren' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      v-if="veranstaltung.data.gastverein"
      class="mt-5 pt-5"
    >
      <v-col
        cols="12"
        :style="`color: ${template.colors.inline_primary_text};`"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn text to="./" exact>
              <v-icon large>mdi-chevron-left</v-icon>
              ZURÜCK
            </v-btn>
          </v-col>
        </v-row>
        <v-card
          class="rounded-xl"
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
        >
          <v-row justify="center">
            <v-col cols="auto" align-self="center" class="pl-5">
              <v-icon large>mdi-check-bold</v-icon>
            </v-col>
            <v-col cols="auto" class="text-center" align-self="center">
              <v-avatar tile>
                <v-img :src="veranstaltung.data.gastverein.logo"></v-img>
              </v-avatar>
            </v-col>
            <v-col align-self="center">
              <p class="mb-0">
                {{ veranstaltung.data.gastverein.team }}
              </p>
              <small>{{ veranstaltung.data.gastverein.name }}</small>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'

export default {
  name: 'Team-Anwesenheit-bearbeiten',
  data() {
    return {
      member: [],
      anwesende_spieler: [],
      newmember: false,
      linkcopysuccess: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
    webShareApiSupported() {
      return navigator.share
    },
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      if (!this.veranstaltung.data.gastpin) {
        var pin = uuidv4()
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .update({
            gastpin: pin,
          })
      }
      this.gastteam_laden()
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    gastteam_laden() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .get()
        .then((snap) => {
          var index
          this.anwesende_spieler = []
          snap.forEach((doc) => {
            if (doc.data().verein.gastverein) {
              this.anwesende_spieler.push(doc.data())
              index = this.anwesende_spieler.length - 1
              this.anwesende_spieler[index].id = doc.id
            }
          })
        })
    },
    multiple_delete() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.uid)
        .collection('Veranstaltung')
        .where('mannschaft', '==', 'Minis')
        .where('beginn.datum', '!=', '2020-10-02')
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.uid)
              .collection('Veranstaltung')
              .doc(doc.id)
              .delete()
          })
        })
    },
    shareViaWebShare() {
      navigator.share({
        title: this.template.var.app_name + ' Gästeteam-Erfassung',
        text:
          'Hey,\nwir nutzen ' +
          this.template.var.app_name +
          ' zur Personenerfassung. Bitte nutze den nachfolgenden Link um dein Team für das ' +
          this.veranstaltung.data.art +
          ' ' +
          this.veranstaltung.data.mannschaft +
          ' gegen ' +
          this.veranstaltung.data.gastmannschaft +
          ' zu erfassen:\n\n',
        url:
          this.template.var.app_url +
          '/g/' +
          this.user.data.alias +
          '/' +
          this.veranstaltung.id +
          '_' +
          this.veranstaltung.data.gastpin,
      })
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    aktualisiereListe() {
      var i
      this.anwesende_spieler = []
      var liste = this.veranstaltung.anwesenheit
      for (i = 0; i < liste.length; i++) {
        if (liste[i].teamid == this.veranstaltung.data.mannschaft) {
          this.anwesende_spieler.push(liste[i].spielerid)
        }
      }
      var member_old = this.member
      this.member = []
      for (i = 0; i < member_old.length; i++) {
        if (this.anwesende_spieler.includes(member_old[i].id)) {
          this.member.push(member_old[i])
          this.member[i].anwesend = true
        } else {
          this.member.push(member_old[i])
          this.member[i].anwesend = false
        }
      }
    },
  },
}
</script>
